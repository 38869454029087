'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.incidents.factory:IncidentPriorityCodesManager

 # @description

###
angular
.module 'mundoAdmin.incidents'
.factory 'IncidentPriorityCodesManager', (
  $mdDialog
  RestUtils
  MundoDefaultManager)->
    IncidentPriorityCodesManagerBase = new MundoDefaultManager()
    IncidentPriorityCodesManagerBase.setUrl('codes')
    IncidentPriorityCodesManagerBase.setAutoTenant()
    IncidentPriorityCodesManagerBase.setNewObject(['code'])
    IncidentPriorityCodesManagerBase.setParentUrl('lpa/incidents/priorities')

    IncidentPriorityCodesManagerBase.getNewForm = ->
      [
        key: 'code'
        name: 'code'
        type: 'input'
        templateOptions:
          label: 'Code'
          placeholder: 'code'
          required: true
      ]

    IncidentPriorityCodesManagerBase.getEditForm = (data) ->
      [
        key: 'code'
        name: 'code'
        type: 'input'
        defaultValue: data.code
        templateOptions:
          label: 'Code'
          placeholder: 'code'
          required: true
      ]
  
  
    IncidentPriorityCodesManagerBase
